import React from "react";
import cx from "classnames";
import { Link } from "gatsby";
import { Image } from "src/components/image";
import { BlockContent } from "src/components/block-content";
import { Player } from "src/components/player";
import { ThreeUpVideo } from "src/components/three-up-video";
import { useSetActiveTags } from "src/context/siteContext";

import styles from "./intro.module.css";

export const ProjectIntro = ({ main, videoMetaData }) => {
  const { media, title, slug, attributes, artistCredits, tags, description } =
    main;

  const hasVideo = media?.video?.url;
  const hasThreeUpVideo = media?.threeUpVideo?.length > 0;
  const hasAudio = media?.audio;
  const hasImage = media?.image;

  const setActiveTags = useSetActiveTags();

  return (
    <div className={cx("bg-blue c-gold", styles.intro)}>
      {media && (
        <div className={styles.mediaContainer}>
          {hasImage && !hasVideo && !hasAudio && !hasThreeUpVideo && (
            <Image
              className={cx("x y", styles.media)}
              imageId={media.image.asset._id}
              alt={title}
              style={{
                height: "100%",
              }}
            />
          )}

          {hasVideo && (
            <Player
              posterImageId={media.image.asset._id}
              url={media.video.url}
              aspectRatio={videoMetaData?.aspectRatio || 16 / 9}
            />
          )}

          {hasThreeUpVideo && <ThreeUpVideo media={media} />}

          {hasAudio && (
            <Player posterImageId={media.image.asset._id} url={media.audio} />
          )}
        </div>
      )}
      <div>
        <h1 className={cx("heading1")}>{title}</h1>
        <div className={styles.row}>
          <div className={styles.rowColumn}>
            <div className={styles.attributesCredits}>
              {attributes && (
                <div className={styles.attributes}>
                  {attributes.map(attribute => (
                    <div className={styles.attribute} key={attribute._key}>
                      <h4 className={cx("label", styles.attributeLabel)}>
                        {attribute.label}
                      </h4>
                      <span className={cx("paragraph", styles.attributeCopy)}>
                        {attribute.copy}
                      </span>
                    </div>
                  ))}
                </div>
              )}

              {artistCredits && (
                <div className={styles.artistCredits}>
                  {artistCredits.map(credits => (
                    <div className={styles.artistCredit} key={credits._key}>
                      <h4 className={cx("label", styles.artistCreditLabel)}>
                        {credits.credit}
                      </h4>
                      <p className={styles.artistCreditArtists}>
                        {credits.artists.map(artist => (
                          <span className={styles.artistName} key={artist._id}>
                            {artist.name}
                          </span>
                        ))}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {tags && (
              <ul className={styles.tagList}>
                {tags.map(tag => (
                  <li className={styles.tag} key={tag._id}>
                    <Link
                      className={cx("tag tag--light", styles.tagLink)}
                      to={`/work/tagged/${tag.slug.current}`}
                      onClick={() => setActiveTags([tag])}
                    >
                      <span>{tag.title}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          {description && (
            <div className={cx(styles.rowColumn, styles.descriptionContainer)}>
              <h4 className={cx("label")}>What</h4>
              <BlockContent
                blocks={description}
                className={styles.description}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
