import React from "react";
import cx from "classnames";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Modal, Slide } from "@material-ui/core";
import { Backdrop } from "src/components/backdrop";
import ScrollLock from "react-scrolllock";

import { ReactComponent as CloseX } from "src/images/svg/CloseX.svg";

import * as styles from "./project-drawer.module.css";

const theme = createMuiTheme({
  transitions: {
    easing: {
      // This is the most common easing curve.
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: "cubic-bezier(0.16, 1, 0.3, 1)",
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },
});

export const ProjectDrawer = ({
  children,
  projectDrawerOpen,
  closeProjectDrawer,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={projectDrawerOpen}
        onClose={closeProjectDrawer}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          active: projectDrawerOpen,
          toggle: closeProjectDrawer,
        }}
      >
        <Slide
          direction="right"
          in={projectDrawerOpen}
          timeout={{
            enter: 600,
            exit: 300,
          }}
        >
          <div
            className={cx("bg-dark-gray c-white", styles.drawer)}
            role="presentation"
          >
            <button
              className={cx("reset", styles.closeButton)}
              onClick={closeProjectDrawer}
            >
              <CloseX />
            </button>
            <ScrollLock>
              <div className={styles.drawerContent}>{children}</div>
            </ScrollLock>
          </div>
        </Slide>
      </Modal>
    </ThemeProvider>
  );
};
