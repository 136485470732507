import React from "react";
import cx from "classnames";
import { Reveal } from "src/components/reveal";

import styles from "./embed-module.module.css";

export const EmbedModule = ({ module }) => {
  const {
    fullWidth,
    code: { code },
  } = module;

  return (
    <Reveal>
      <div className={cx(styles.embedModule)}>
        {code && (
          <div
            className={cx(
              styles.embedContainer,
              fullWidth ? styles.fullWidth : ""
            )}
          >
            <div className="x" dangerouslySetInnerHTML={{ __html: code }} />
          </div>
        )}
      </div>
    </Reveal>
  );
};
