import React from "react";
import cx from "classnames";

import styles from "./related-articles.module.css";

export const RelatedArticles = ({ articles }) => {
  if (articles?.length < 1) {
    return null;
  }
  return (
    <div className={cx(styles.articles)}>
      <h4 className={cx("label")}>Related Articles</h4>
      <ul className={styles.articlelist}>
        {articles.map(article => {
          const date = new Date(article.publishDate);
          const formattedDate = `${
            date.getUTCMonth() + 1
          }/${date.getUTCDate()}/${date.getUTCFullYear()}`;
          return (
            <li className={cx(styles.articleRow)} key={article._key}>
              <a
                className={styles.articleLink}
                href={article.url}
                target="_blank"
              >
                <div className={styles.publicationTitle}>
                  <span className={styles.publication}>
                    {article.publication}
                  </span>
                  <span className={styles.title}>{article.title}</span>
                </div>
                {article.publishDate && (
                  <span className={styles.date}>{formattedDate}</span>
                )}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
