import React from "react";
import cx from "classnames";
import { Reveal } from "src/components/reveal";
import { Image } from "src/components/image";
import { Player } from "src/components/player";
import { ThreeUpVideo } from "src/components/three-up-video";

import styles from "./media-module.module.css";

export const MediaModule = ({ module }) => {
  const { fullWidth, media } = module;

  const hasVideo = media?.video?.url;
  const hasThreeUpVideo = media?.threeUpVideo?.length > 0;
  const hasAudio = media?.audio;
  const hasImage = media?.image;

  return (
    <Reveal>
      <div className={cx(styles.mediaModule)}>
        {media && (
          <div
            className={cx(
              styles.mediaContainer,
              fullWidth ? styles.fullWidth : ""
            )}
          >
            {hasImage && !hasVideo && !hasAudio && !hasThreeUpVideo && (
              <Image
                className={cx("x y")}
                imageId={media.image.asset._id}
                alt={media.image.alt}
                style={{
                  height: "100%",
                }}
              />
            )}

            {hasVideo && (
              <Player
                posterImageId={media.image.asset._id}
                url={media.video.url}
                enableCustomAspectRatio={true}
                aspectRatio={media.video.metadata?.aspectRatio || 16 / 9}
                className={
                  media.video.metadata?.aspectRatio < 0.8
                    ? styles.limitWidth
                    : ""
                }
              />
            )}

            {hasThreeUpVideo && <ThreeUpVideo media={media} />}

            {hasAudio && (
              <Player posterImageId={media.image.asset._id} url={media.audio} />
            )}
          </div>
        )}
      </div>
    </Reveal>
  );
};
