import React, { useState, useRef, useCallback, useLayoutEffect } from "react";
import { useInView } from "react-intersection-observer";
import cx from "classnames";

import styles from "./reveal.module.css";

export const Reveal = ({
  children,
  delay = 0,
  threshold = 0.25,
  className,
  disabled,
}) => {
  // console.log(disabled);
  const [initialized, setInitialized] = useState(false);
  const ref = useRef();
  const [inViewRef, inView] = useInView({
    threshold,
    triggerOnce: true,
  });

  // Use `useCallback` so we don't recreate the function on each render - Could result in infinite loop
  const setRefs = useCallback(
    node => {
      // Ref's from useRef needs to have the node assigned to `current`
      ref.current = node;
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node);
    },
    [inViewRef]
  );

  useLayoutEffect(() => {
    setInitialized(true);
  }, []);

  return (
    <div
      className={cx(
        !disabled && initialized ? styles.root : "",
        !disabled && inView ? styles.reveal : "",
        className
      )}
      style={{
        transitionDelay: `${delay}ms`,
      }}
      ref={setRefs}
    >
      {children}
    </div>
  );
};
