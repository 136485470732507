import React from "react";
import { MediaModule } from "src/components/project/media-module";
import { CopyModule } from "src/components/project/copy-module";
import { EmbedModule } from "src/components/project/embed-module";

const ProjectModule = ({ type, data }) => {
  switch (type) {
    case "projectMediaModule":
      return <MediaModule module={data} />;
    case "projectCopyModule":
      return <CopyModule module={data} />;
    case "projectEmbedModule":
      return <EmbedModule module={data} />;

    default:
      return null;
    // return <p className="m1 p1 ac">{type}</p>;
  }
};

export const renderProjectModules = modules => {
  if (modules) {
    return modules.map(module => {
      return (
        <ProjectModule key={module._key} type={module._type} data={module} />
      );
    });
  }
};
