import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Reveal } from "src/components/reveal";
import { BlockContent } from "src/components/block-content";
import { Image } from "src/components/image";
import { getInternalLinkPath } from "src/utils/getInternalLinkPath";

import styles from "./copy-module.module.css";

export const CopyModule = ({ module }) => {
  const { headline, copy } = module;

  return (
    <Reveal>
      <div className={cx(styles.copyModule)}>
        {headline && (
          <h2 className={cx("heading8", styles.headline)}>{headline}</h2>
        )}

        <BlockContent className={cx(styles.copy)} blocks={copy} />
      </div>
    </Reveal>
  );
};
