import React, { useEffect, useState } from "react";
import { navigate, PageRenderer } from "gatsby";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { ProjectDrawer } from "src/components/project-drawer";
import { SEO } from "src/components/SEO";
import { ProjectIntro } from "src/components/project/intro";
import { RelatedArticles } from "src/components/project/related-articles";
import { useStoreContext } from "src/context/siteContext";
import { renderProjectModules } from "src/utils/renderProjectModules";
import { blocksToPlainText } from "src/utils/blocksToPlainText";
import { useSetCustomCursorType } from "src/context/siteContext";

const ProjectContent = ({ main, videoMetaData, location }) => {
  const { body, relatedArticles } = main;

  // construct meta data from project content
  const meta = {
    metaTitle: main.title,
    metaDescription: blocksToPlainText(main.description),
    openImage: {
      asset: {
        url: main.media?.image?.asset?.url?.concat("?fm=jpg"),
      },
    },
  };

  return (
    <>
      <SEO metaInfo={meta} path={location?.pathname} slug={main.slug.current} />
      <ProjectIntro main={main} videoMetaData={videoMetaData} />
      <div style={{ paddingBottom: `66px` }}>{renderProjectModules(body)}</div>
      {relatedArticles && (
        <RelatedArticles articles={relatedArticles.articles} />
      )}
    </>
  );
};

const Project = ({ location, path, pageContext }) => {
  const setCustomCursorType = useSetCustomCursorType();

  const { activeTags } = useStoreContext();

  const [indexPathname, setIndexPathname] = useState("/");

  const isModal = location?.state?.modal || false;

  const { main, videoMetaData } = pageContext;

  const [projectDrawerOpen, setProjectDrawerOpen] = useState(isModal);

  useEffect(() => {
    // reset custom cursor when mounting case study
    setCustomCursorType("default");
  }, []);

  useEffect(() => {
    let joinedTags = "";
    if (activeTags.length > 0) {
      joinedTags = activeTags.map(tag => tag.slug.current).join("+");
      setIndexPathname(`/work/tagged/${joinedTags}`);
    }
  }, [activeTags]);

  const closeProjectDrawer = () => {
    setProjectDrawerOpen(false);

    setTimeout(() => {
      navigate(indexPathname, {
        state: {
          modal: false,
          shouldUpdateScroll: false,
          disableReveals: true,
        },
      });
    }, 300);
  };

  if (isModal) {
    return (
      <>
        <PageRenderer
          key={indexPathname}
          location={{
            pathname: indexPathname,
            state: {
              disableReveals: true,
              renderHeader: false,
              renderFooter: false,
              shouldUpdateScroll: false,
            },
          }}
          path={indexPathname}
        />
        <ProjectDrawer
          projectDrawerOpen={projectDrawerOpen}
          closeProjectDrawer={closeProjectDrawer}
        >
          <ProjectContent
            location={location}
            main={main}
            videoMetaData={videoMetaData}
          />
        </ProjectDrawer>
      </>
    );
  }

  return (
    <RevealOnMount location={location}>
      <div className="bg-dark-gray c-white">
        <ProjectContent location={location} main={main} />
      </div>
    </RevealOnMount>
  );
};

export default Project;
